import React from "react";
import { motion } from "framer-motion";
import { Reveal } from "../Common/Reveal";

const SkillsDescription = () => {
  return (
    <div className="text-left lg:pt-0">
      <motion.h2
        initial={{ x: 50, opacity: 0 }}
        transition={{ duration: 1 }}
        whileInView={{ opacity: 1, x: 0 }}
        viewport={{ once: true }}
        className="font-medium text-2xl text-[#DB6B31] dark:text-[#DB6B31] mt-5 mb-5"
      >
        <Reveal width="100%">
          A look at all the programming languages, libraries, and tools I've
          worked with.
        </Reveal>
      </motion.h2>
      <motion.p
        initial={{ x: 50, opacity: 0 }}
        transition={{ duration: 1 }}
        whileInView={{ opacity: 1, x: 0 }}
        viewport={{ once: true }}
        className="text-sm font-light"
      >
        In the expansive field of Software Development, I've acquired diverse
        experience in &nbsp;
        <span className="font-semibold">Python Applications</span>, focusing on <span className="font-semibold">Artificial Intelligence, Machine Learning and Gen AI.</span>
      </motion.p>

      <motion.h2
        initial={{ x: 50, opacity: 0 }}
        transition={{ duration: 1 }}
        whileInView={{ opacity: 1, x: 0 }}
        viewport={{ once: true }}
        className="font-medium text-lg text-[#DB6B31] dark:text-[#DB6B31] mt-5"
      >
        <Reveal width="100%">App Development</Reveal>
      </motion.h2>
      <motion.p
        initial={{ x: 50, opacity: 0 }}
        transition={{ duration: 1 }}
        whileInView={{ opacity: 1, x: 0 }}
        viewport={{ once: true }}
        className="text-sm font-light"
      >
        Includes Python GUI projects, often in conjunction with academic submissions, while also extending to crafting Android applications using Java and XML. Additionally, exploration into Kotlin and Jetpack Compose has been a part of my development endeavors.
      </motion.p>

      <div className="flex">
        <div className="">
          <motion.h3
            initial={{ x: 50, opacity: 0 }}
            transition={{ duration: 1 }}
            whileInView={{ opacity: 1, x: 0 }}
            viewport={{ once: true }}
            className="font-medium text-lg text-[#DB6B31] dark:text-[#DB6B31] mt-5"
          >
            Generative AI
          </motion.h3>
          <motion.p
            initial={{ x: 50, opacity: 0 }}
            transition={{ duration: 1 }}
            whileInView={{ opacity: 1, x: 0 }}
            viewport={{ once: true }}
            className="text-sm font-light"
          >
            I am currently learning Generative AI, and I am also pursuing Conversational AI as my stream at university, and thus I have now been doing AI and ML/DL for a while, I have also implemented these concepts in projects as well as during internships. 
          </motion.p>
        </div>
      </div>
      <div className="flex">
        <div className="">
          <motion.h3
            initial={{ x: 50, opacity: 0 }}
            transition={{ duration: 1 }}
            whileInView={{ opacity: 1, x: 0 }}
            viewport={{ once: true }}
            className="font-medium text-lg text-[#DB6B31] dark:text-[#DB6B31] mt-5"
          >
            Cloud Technologies
          </motion.h3>
          <motion.p
            initial={{ x: 50, opacity: 0 }}
            transition={{ duration: 1 }}
            whileInView={{ opacity: 1, x: 0 }}
            viewport={{ once: true }}
            className="text-sm font-light"
          >
            I have worked with AWS extensively thanks to my work at Backslash Computing Society, where I was part of organising a 3 day event, and the technical part of that event involved making an online cryptic hunt, and I handled the deployment part of the process, which involved setting up the AWS services, and deploying the website on the cloud. I have also had AWS in my coursework at university, which has further strengthened my understanding of the cloud, as well as of more AWS Services. Additionally I have also dabbled with GCP as I was part of the Google Cloud Faciliatator program  
          </motion.p>
        </div>
      </div>
    </div>
  );
};

export default SkillsDescription;

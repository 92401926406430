// import { FaArrowDown } from "react-icons/fa";
// import { TypeAnimation } from "react-type-animation";
// import { motion } from "framer-motion";
// import ActiveComponent from "./ActiveComponent";
// import BackgroundCircles from "./BackgroundCircles";
// import SliderText from "../Slider/SliderText";
// import { useState } from "react";
// import logo from "../../assets/yellow/logo.png";
// const animation = {
//   hide: { x: -32, opacity: 0 },
//   show: {
//     x: 0,
//     opacity: 1,
//   },
// };

// const Hero = () => {
//   window.addEventListener("scroll", function () {
//     const downArrow = this.document.querySelector(".down-arrow");

//     if (this.scrollY >= 1200) downArrow.classList.add("hide-down-arrow");
//     else downArrow.classList.remove("hide-down-arrow");
//   });

//   const [sliderTextLoader, setSliderTextLoader] = useState(false);

//   setInterval(() => {
//     setSliderTextLoader(true);
//   }, 3500);

//   return (
//     <section
//       id="hero"
//       className="relative min-h-screen flex flex-col justify-center items-center p-5 text-center pt-32 z-30 overflow-y-hidden overflow-x-hidden"
//     >
//       {!sliderTextLoader && <BackgroundCircles />}
//       {sliderTextLoader && (
//         <motion.div
//           initial={{ opacity: 0 }}
//           transition={{ duration: 0.6 }}
//           whileInView={{ opacity: 1 }}
//           viewport={{ once: true }}
//         >
//           <SliderText />
//         </motion.div>
//       )}

// <div className="flex items-center mb-3">
//         <motion.img
//           src={logo}
//           alt="Logo"
//           initial={animation.hide}
//           animate={animation.show}
//           transition={{ delay: 0.5 }}
//           className="h-auto max-h-48 w-auto mr-3 rounded-full"
//         />
//         </div>

//       <motion.h2
//         initial={animation.hide}
//         animate={animation.show}
//         transition={{ delay: 0.5 }}
//         className="text-sm pt-3 md:text-xl text-[#DB6B31] font-bold z-0"
//       >
//         Software Developer
//       </motion.h2>

//       <motion.div
//         initial={animation.hide}
//         animate={animation.show}
//         transition={{ delay: 0.5 }}
//         className="py-3 text-xl md:text-3xl lg:text-5xl text-center font-bold leading-[1] z-0"
//       >
//         <span className="text-slate-700 dark:text-slate-200">
//           <TypeAnimation
//             sequence={["Welcome ✌️", 2000, "Vanshaj Raghuvanshi"]}
//             speed={25}
//             wrapper="span"
//             repeat={Infinity}
//           />
//         </span>
//       </motion.div>

//       <motion.div className="z-10">
//         <ActiveComponent />
//       </motion.div>

//       <div className="mt-20 mb-0 down-arrow z-0">
//         <FaArrowDown className="text-[#DB6B31] text-3xl animate-bounce " />
//       </div>
//     </section>
//   );
// };

// export default Hero;


// import { FaArrowDown } from "react-icons/fa";
// import { TypeAnimation } from "react-type-animation";
// import { motion } from "framer-motion";
// import ActiveComponent from "./ActiveComponent";
// import BackgroundCircles from "./BackgroundCircles";
// import SliderText from "../Slider/SliderText";
// import { useState, useEffect } from "react";
// import logo from "../../assets/yellow/logo.png";
// import ThreeDModel from "../ThreeDModel/ThreeDModel"; // Import 3D model

// const animation = {
//   hide: { x: -32, opacity: 0 },
//   show: {
//     x: 0,
//     opacity: 1,
//   },
// };

// const Hero = () => {
//   const [sliderTextLoader, setSliderTextLoader] = useState(false);
//   const [show3D, setShow3D] = useState(false); // Toggle state for 3D model
//   const [isMobile, setIsMobile] = useState(false); // Check if device is mobile

//   useEffect(() => {
//     // Detect mobile devices
//     const checkMobile = () => {
//       const isMobileDevice =
//         /Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
//           navigator.userAgent
//         );
//       setIsMobile(isMobileDevice);
//     };

//     checkMobile();
//     window.addEventListener("resize", checkMobile);
//     return () => window.removeEventListener("resize", checkMobile);
//   }, []);

//   useEffect(() => {
//     const interval = setInterval(() => {
//       setSliderTextLoader(true);
//     }, 3500);
//     return () => clearInterval(interval);
//   }, []);

//   // Handle double-click or double-tap for toggling
//   const handleDoubleClick = () => {
//     if (!isMobile) setShow3D((prev) => !prev); // Prevent toggle on mobile
//   };

//   return (
//     <section
//       id="hero"
//       className="relative min-h-screen flex flex-col justify-center items-center p-5 text-center pt-32 z-30 overflow-y-hidden overflow-x-hidden"
//     >
//       {!sliderTextLoader && <BackgroundCircles />}
//       {sliderTextLoader && (
//         <motion.div
//           initial={{ opacity: 0 }}
//           transition={{ duration: 0.6 }}
//           whileInView={{ opacity: 1 }}
//           viewport={{ once: true }}
//         >
//           <SliderText />
//         </motion.div>
//       )}

//       <div className="flex items-center mb-3" onDoubleClick={handleDoubleClick}>
//         {show3D ? (
//           <motion.div
//             initial={animation.hide}
//             animate={animation.show}
//             transition={{ delay: 0.5 }}
//           >
//             <ThreeDModel />
//           </motion.div>
//         ) : (
//           <motion.img
//             src={logo}
//             alt="Logo"
//             initial={animation.hide}
//             animate={animation.show}
//             transition={{ delay: 0.5 }}
//             className="h-auto max-h-48 w-auto mr-3 rounded-full"
//           />
//         )}
//       </div>

//       <motion.h2
//         initial={animation.hide}
//         animate={animation.show}
//         transition={{ delay: 0.5 }}
//         className="text-sm pt-3 md:text-xl text-[#DB6B31] font-bold z-0"
//       >
//         Software Developer
//       </motion.h2>

//       <motion.div
//         initial={animation.hide}
//         animate={animation.show}
//         transition={{ delay: 0.5 }}
//         className="py-3 text-xl md:text-3xl lg:text-5xl text-center font-bold leading-[1] z-0"
//       >
//         <span className="text-slate-700 dark:text-slate-200">
//           <TypeAnimation
//             sequence={["Welcome ✌️", 2000, "Vanshaj Raghuvanshi"]}
//             speed={25}
//             wrapper="span"
//             repeat={Infinity}
//           />
//         </span>
//       </motion.div>

//       <motion.div className="z-10">
//         <ActiveComponent />
//       </motion.div>

//       <div className="mt-20 mb-0 down-arrow z-0">
//         <FaArrowDown className="text-[#DB6B31] text-3xl animate-bounce " />
//       </div>
//     </section>
//   );
// };

// export default Hero;

import { FaArrowDown } from "react-icons/fa";
import { TypeAnimation } from "react-type-animation";
import { motion } from "framer-motion";
import ActiveComponent from "./ActiveComponent";
import BackgroundCircles from "./BackgroundCircles";
import SliderText from "../Slider/SliderText";
import { useState, useEffect, useRef } from "react";
import logo from "../../assets/yellow/logo.png";
import ThreeDModel from "../ThreeDModel/ThreeDModel"; // Import 3D model

const animation = {
  hide: { x: -32, opacity: 0 },
  show: {
    x: 0,
    opacity: 1,
  },
};

const Hero = () => {
  const [sliderTextLoader, setSliderTextLoader] = useState(false);
  const [show3D, setShow3D] = useState(false); // Toggle state for 3D model
  const [isMobile, setIsMobile] = useState(false); // Check if device is mobile
  const lastTouch = useRef(0); // Track time between touches for double-tap
  const touchTimeout = useRef(null); // Timeout to handle debounce
  const clickTimeout = useRef(null); // Timeout for double-click throttling

  // Mobile device detection
  useEffect(() => {
    const checkMobile = () => {
      const isMobileDevice =
        /Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
          navigator.userAgent
        );
      setIsMobile(isMobileDevice);
    };

    checkMobile();
    window.addEventListener("resize", checkMobile);
    return () => window.removeEventListener("resize", checkMobile);
  }, []);

  // Set sliderTextLoader after 3.5 seconds
  useEffect(() => {
    const interval = setInterval(() => {
      setSliderTextLoader(true);
    }, 3500);
    return () => clearInterval(interval);
  }, []);

  // Handle double-click for desktop or double-tap for mobile
  const handleDoubleClick = () => {
    if (clickTimeout.current) {
      // If a double-click is already in progress, do nothing
      return;
    }

    // Set the timeout to throttle multiple clicks
    clickTimeout.current = setTimeout(() => {
      setShow3D((prev) => !prev); // Toggle 3D model on click
      clickTimeout.current = null; // Reset timeout after toggle
    }, 300); // Delay for 300ms to handle rapid clicks
  };

  // Handle mobile touch events to detect double-tap with debounce
  const handleTouch = (event) => {
    const currentTime = new Date().getTime();
    const timeDiff = currentTime - lastTouch.current;

    // If the time difference is less than 300ms, it's a double-tap
    if (timeDiff < 300) {
      // Clear any previous timeouts if double-tap detected early
      clearTimeout(touchTimeout.current);

      // Trigger the double-click function
      handleDoubleClick();
    } else {
      // If no double-tap detected, set a timeout to reset the state
      touchTimeout.current = setTimeout(() => {
        lastTouch.current = 0; // Reset for the next tap
      }, 300);
    }

    lastTouch.current = currentTime;
  };

  return (
    <section
      id="hero"
      className="relative min-h-screen flex flex-col justify-center items-center p-5 text-center pt-32 z-30 overflow-y-hidden overflow-x-hidden"
    >
      {!sliderTextLoader && <BackgroundCircles />}
      {sliderTextLoader && (
        <motion.div
          initial={{ opacity: 0 }}
          transition={{ duration: 0.6 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true }}
        >
          <SliderText />
        </motion.div>
      )}

      <div
        className="flex items-center mb-3"
        onDoubleClick={handleDoubleClick} // Handle double-click for desktop
        onTouchStart={isMobile ? handleTouch : null} // Handle touch for mobile (only on mobile)
      >
        {show3D ? (
          <motion.div
            initial={animation.hide}
            animate={animation.show}
            transition={{ delay: 0.5 }}
          >
            <ThreeDModel />
          </motion.div>
        ) : (
          <motion.img
            src={logo}
            alt="Logo"
            initial={animation.hide}
            animate={animation.show}
            transition={{ delay: 0.5 }}
            className="h-auto max-h-48 w-auto mr-3 rounded-full"
          />
        )}
      </div>

      <motion.h2
        initial={animation.hide}
        animate={animation.show}
        transition={{ delay: 0.5 }}
        className="text-sm pt-3 md:text-xl text-[#DB6B31] font-bold z-0"
      >
        Software Developer
      </motion.h2>

      <motion.div
        initial={animation.hide}
        animate={animation.show}
        transition={{ delay: 0.5 }}
        className="py-3 text-xl md:text-3xl lg:text-5xl text-center font-bold leading-[1] z-0"
      >
        <span className="text-slate-700 dark:text-slate-200">
          <TypeAnimation
            sequence={["Welcome ✌️", 2000, "Vanshaj Raghuvanshi"]}
            speed={25}
            wrapper="span"
            repeat={Infinity}
          />
        </span>
      </motion.div>

      <motion.div className="z-10">
        <ActiveComponent />
      </motion.div>

      <div className="mt-20 mb-0 down-arrow z-0">
        <FaArrowDown className="text-[#DB6B31] text-3xl animate-bounce " />
      </div>
    </section>
  );
};

export default Hero;


import React from "react";
import styled from "styled-components";

const Switch = ({ darkMode, setDarkMode }) => {
  return (
    <StyledWrapper>
      <label>
        <input
            className="slider"
          type="checkbox"
          checked={darkMode}
          onChange={() => setDarkMode(!darkMode)}
        />
        <div className="switch">
          <div className="suns" />
          <div className="moons">
            <div className="star star-1" />
            <div className="star star-2" />
            <div className="star star-3" />
            <div className="star star-4" />
            <div className="star star-5" />
            <div className="first-moon" />
          </div>
          <div className="sand" />
          <div className="bb8">
            <div className="antennas">
              <div className="antenna short" />
              <div className="antenna long" />
            </div>
            <div className="head">
              <div className="stripe one" />
              <div className="stripe two" />
              <div className="eyes">
                <div className="eye one" />
                <div className="eye two" />
              </div>
              <div className="stripe detail">
                <div className="detail zero" />
                <div className="detail zero" />
                <div className="detail one" />
                <div className="detail two" />
                <div className="detail three" />
                <div className="detail four" />
                <div className="detail five" />
                <div className="detail five" />
              </div>
              <div className="stripe three" />
            </div>
            <div className="ball">
              <div className="lines one" />
              <div className="lines two" />
              <div className="ring one" />
              <div className="ring two" />
              <div className="ring three" />
            </div>
            <div className="shadow" />
          </div>
        </div>
      </label>
    </StyledWrapper>
  );
};

const StyledWrapper = styled.div`
  .switch {
    --toggle-size: 0.37;
    top:3px 
    position: relative;
    left:-5px
  }

  .switch *, .switch *::after, *::before {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
  }

  .switch {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    width: 170px;
    height: 90px;
    background-color: #b7cecc;
    position: relative;
    border-radius: 999px;
    -webkit-transition: .4s;
    -o-transition: .4s;
    transition: .4s;
    cursor: pointer;
    -webkit-transform: scale(var(--toggle-size));
    -ms-transform: scale(var(--toggle-size));
    transform: scale(var(--toggle-size));
  }

  .moons {
    position: absolute;
    width: 100px;
    height: 65px;
    overflow: hidden;
    top: 0;
    left: -10px;
  }

  .moons .star {
    position: absolute;
    width: 1px;
    height: 1px;
    top: 45px;
    left: 30px;
    border-radius: 999px;
    background-color: #ffffff;
  }

  .moons .star.star-1 {
    top: 100%;
    left: 75px;
    -webkit-transition: .2s;
    -o-transition: .2s;
    transition: .2s;
  }

  .moons .star.star-2 {
    top: 100%;
    left: 65px;
    -webkit-transition: .3s;
    -o-transition: .3s;
    transition: .3s;
  }

  .moons .star.star-3 {
    top: 100%;
    left: 85px;
    -webkit-transition: .4s;
    -o-transition: .4s;
    transition: .4s;
  }

  .moons .star.star-4 {
    top: 100%;
    left: 60px;
    -webkit-transition: .5s;
    -o-transition: .5s;
    transition: .5s;
  }

  .moons .star.star-5 {
    top: 100%;
    left: 44px;
    -webkit-transition: .6s;
    -o-transition: .6s;
    transition: .6s;
  }

  .moons .first-moon {
    position: absolute;
    -webkit-transition: .4s;
    -o-transition: .4s;
    transition: .4s;
    width: 30px;
    height: 30px;
    top: 100%;
    left: 30px;
    background-color: #e0d6d6;
    border-radius: 999px;
  }

  .moons::before, .moons::after {
    -webkit-transition: .6s;
    -o-transition: .6s;
    transition: .6s;
    content: "";
    position: absolute;
    width: 8px;
    height: 8px;
    background-color: #dde4e6;
    border-radius: 999px;
    top: 100%;
    left: 70px;
  }

  .moons::after {
    width: 5px;
    height: 5px;
    left: 81px;
    -webkit-transition: .8s;
    -o-transition: .8s;
    transition: .8s;
  }

  .sand {
    position: absolute;
    width: 100%;
    height: 45px;
    bottom: 0px;
    left: 0;
    border-radius: 0 0 999px 999px;
    overflow: hidden;
  }

  .suns {
    position: absolute;
    border-radius: 1in;
    width: 40px;
    height: 40px;
    top: 10px;
    right: 30px;
    box-shadow: 0 0 15px #EAB308;
    -webkit-box-shadow: 0 0 15px #EAB308;
    background-color: #EAB308;
    -webkit-transition: .6s;
    -o-transition: .6s;
    transition: .6s;
  }

  .sand::before {
    position: absolute;
    width: 100%;
    height: 25px;
    bottom: 0px;
    left: 0;
    content: "";
    background-color: #B69C77;
    border-radius: 0 0 999px 999px;
  }

  .bb8 {
    position: absolute;
    left: -18%;
    width: 140px;
    -webkit-transform: scale(0.45);
    -ms-transform: scale(0.45);
    transform: scale(0.45);
    -webkit-transition: left .4s;
    -o-transition: left .4s;
    transition: left .4s;
  }

  .slider {
    appearance: none;
  }

  .antennas {
    position: absolute;
    -webkit-transition: all 0.4s;
    -o-transition: all 0.4s;
    transition: all 0.4s;
    left: 28%;
  }

  .antenna {
    background: #e0d2be;
    position: absolute;
    width: 2px;
  }

  .antenna.short {
    height: 20px;
    top: -65px;
    left: 50px;
  }

  .antenna.long {
    border-top: 6px solid #020204;
    border-bottom: 6px solid #020204;
    height: 36px;
    top: -80px;
    left: 56px;
  }

  .head {
    -webkit-transition: -webkit-transform .4s;
    transition: -webkit-transform .4s;
    -o-transition: transform .4s;
    transition: transform .4s, -webkit-transform .4s;
    background-color: ghostwhite;
    border-radius: 90px 90px 25px 25px;
    -moz-border-radius: 90px 90px 25px 25px;
    -webkit-border-radius: 90px 90px 25px 25px;
    height: 63px;
    margin-left: -45px;
    overflow: hidden;
    position: absolute;
    width: 95px;
    z-index: 1;
    top: -56px;
    left: 56%;
  }

  .head .stripe {
    position: absolute;
    width: 100%;
  }

  .head .stripe.one {
    background: #7699B7;
    height: 7px;
    opacity: 0.8;
    z-index: 1;
    top: 3px;
  }

  .head .stripe.two {
    background: #CD7640;
    height: 4px;
    top: 14px;
  }

  .head .stripe.three {
    background: #999;
    height: 4px;
    opacity: 0.5;
    bottom: 3px;
  }

  .head .stripe.detail {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 200px;
    bottom: 7px;
    left: -38%;
    -webkit-transition: left 0.4s;
    -o-transition: left 0.4s;
    transition: left 0.4s;
  }

  .head .detail {
    height: 7px;
  }

  .head .detail.zero {
    background-color: #CD7640;
    width: 2%;
    margin-left: 3px;
  }

  .head .detail.one {
    background-color: #CD7640;
    width: 8%;
    margin-left: 3px;
  }

  .head .detail.two {
    background-color: #CD7640;
    width: 6%;
    margin-left: 5px;
  }

  .head .detail.three {
    background-color: #CD7640;
    width: 4%;
    margin-left: 45px;
    height: 5px;
    margin-top: 2px;
  }

  .head .detail.four {
    background-color: #CD7640;
    width: 10%;
    margin-left: 4px;
  }

  .head .detail.five {
    background-color: #CD7640;
    width: 2%;
    margin-left: 3px;
  }

  .head .eyes {
    display: inline-block;
    height: 100%;
    position: absolute;
    width: 100%;
    -webkit-transition: left 0.4s;
    -o-transition: left 0.4s;
    transition: left 0.4s;
    left: 22%;
  }

  .head .eye {
    border-radius: 50%;
    display: block;
    position: absolute;
  }

  .head .eye.one {
    background: #020204;
    border: 4px solid lightgray;
    height: 30px;
    width: 30px;
    top: 12px;
    left: 12%;
  }

  .head .eye.one:after {
    background: white;
    border-radius: 50%;
    content: "";
    display: block;
    height: 3px;
    position: absolute;
    width: 3px;
    top: 4px;
    right: 4px;
  }

  .head .eye.two {
    background-color: lightgrey;
    border: 1px solid #020204;
    height: 16px;
    width: 16px;
    top: 30px;
    left: 40%;
  }

  .head .eye.two:after {
    background: #020204;
    border-radius: 50%;
    content: "";
    display: block;
    height: 10px;
    position: absolute;
    width: 10px;
    top: 2px;
    left: 2px;
  }

  .ball {
    background-color: ghostwhite;
    border-radius: 50%;
    height: 165px;
    overflow: hidden;
    position: relative;
    width: 165px;
    -webkit-transition: -webkit-transform .4s;
    transition: -webkit-transform .4s;
    -o-transition: transform .4s;
    transition: transform .4s, -webkit-transform .4s;
  }

  .lines {
    border: 2px solid #B19669;
    border-radius: 50%;
    height: 400px;
    opacity: 0.6;
    position: absolute;
    width: 400px;
  }

  .lines.two {
    top: -10px;
    left: -250px;
  }

  .ring {
    background: #CD7640;
    border-radius: 50%;
    height: 70px;
    margin-left: -35px;
    position: absolute;
    width: 70px;
  }

  .ring:after {
    background-color: ghostwhite;
    border-radius: 50%;
    content: "";
    display: block;
    height: 73%;
    margin-top: -36%;
    margin-left: -36%;
    position: absolute;
    width: 73%;
    top: 50%;
    left: 50%;
  }

  .ring.one {
    margin-left: -40px;
    height: 90px;
    width: 100px;
    top: 2%;
    left: 42%;
  }

  .ring.two {
    height: 40px;
    width: 80px;
    -ms-transform: rotate(50deg);
    -webkit-transform: rotate(50deg);
    transform: rotate(50deg);
    top: 65%;
    left: 8%;
  }

  .ring.two:after {
    top: 100%;
  }

  .ring.three {
    height: 37px;
    width: 80px;
    -ms-transform: rotate(-50deg);
    -webkit-transform: rotate(-50deg);
    transform: rotate(-50deg);
    top: 68%;
    left: 84%;
  }

  .ring.three:after {
    top: 110%;
  }

  .shadow {
    background: #3A271C;
    -webkit-box-shadow: 5px 0 50px #3A271C;
    box-shadow: 5px 0 50px #3A271C;
    border-radius: 50%;
    height: 23.3333333333px;
    opacity: 0.25;
    position: absolute;
    width: 110px;
    left: 28px;
    z-index: -1;
    bottom: -8px;
  }

  /* actions */

  .slider:checked+ .switch .bb8 {
    left: 29%;
  }

  .slider:checked + .switch  .bb8 .ball {
    -webkit-transform: rotate(180deg);
    -ms-transform: rotate(180deg);
    transform: rotate(180deg);
  }

  .slider:hover+ .switch .bb8 .eyes {
    left: 60%;
  }

  .slider:checked:hover+ .switch .bb8 .eyes {
    left: -20%;
  }

  .slider:active+ .switch .bb8 .head {
    -webkit-transform: translate(35px, 3px) rotateZ(17deg);
    -ms-transform: translate(35px, 3px) rotate(17deg);
    transform: translate(30px, 3px) rotateZ(17deg);
  }

  .slider:checked:active+ .switch .bb8 .head {
    -webkit-transform: translate(-35px, 6px) rotateZ(-17deg);
    -ms-transform: translate(-35px, 6px) rotate(-17deg);
    transform: translate(-35px, 6px) rotateZ(-17deg);
  }

  .slider:active+.switch .antennas {
    -webkit-transform: translate(35px, -20px) rotateZ(17deg);
    -ms-transform: translate(35px, -20px) rotate(17deg);
    transform: translate(35px, -20px) rotateZ(17deg);
  }

  .slider:active:not(:hover)+.switch .antennas {
    -webkit-transform: translate(35px, -10px) rotateZ(17deg);
    -ms-transform: translate(35px, -10px) rotate(17deg);
    transform: translate(35px, -10px) rotateZ(17deg);
  }

  .slider:checked:active+.switch .antennas {
    -webkit-transform: translate(-35px, 10px) rotateZ(-17deg);
    -ms-transform: translate(-35px, 10px) rotate(-17deg);
    transform: translate(-35px, 15px) rotateZ(-17deg);
  }

  .slider:checked:active:not(:hover)+.switch .antennas {
    -webkit-transform: translate(-30px, 20px) rotateZ(-17deg);
    -ms-transform: translate(-30px, 20px) rotate(-17deg);
    transform: translate(-25px, 25px) rotateZ(-17deg);
  }

  .slider:hover+ .switch .antennas, .slider:checked+ .switch .antennas {
    left: 6%;
  }

  .slider:hover+ .switch .stripe.detail, .slider:checked+ .switch .stripe.detail {
    left: 0;
  }

  .slider:checked:hover+ .switch .antennas {
    left: 28%;
  }

  .slider:checked:hover+ .switch .stripe.detail {
    left: -38%;
  }

  .slider:checked + .switch {
    background-color: #112350;
  }

  .slider:checked + .switch .suns {
    top: 50px;
  }

  .slider:checked + .switch .moons .first-moon {
    top: 15px;
    -webkit-box-shadow: 0 0 10px #B8CCCD;
    box-shadow: 0 0 10px #B8CCCD;
  }

  .slider:checked + .switch .moons::after {
    top: 38px;
    -webkit-box-shadow: 0 0 15px #B8CCCD;
    box-shadow: 0 0 15px #B8CCCD;
  }

  .slider:checked + .switch .moons::before {
    top: 34px;
    -webkit-box-shadow: 0 0 15px #B8CCCD;
    box-shadow: 0 0 15px #B8CCCD;
  }

  .slider:checked + .switch .moons .star {
    -webkit-box-shadow: 0 0 20px 2px #fff;
    box-shadow: 0 0 20px 2px #fff;
  }

  .slider:checked + .switch .moons .star.star-1 {
    top: 20px;
  }

  .slider:checked + .switch .moons .star.star-2 {
    top: 15px;
  }

  .slider:checked + .switch .moons .star.star-3 {
    top: 15px;
  }

  .slider:checked + .switch .moons .star.star-4 {
    top: 45px;
  }

  .slider:checked + .switch .moons .star.star-5 {
    top: 53px;
  }`;

export default Switch;

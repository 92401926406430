function FeaturedCard({ icon, title, desc }) {
  return (
    <div className="dark:border-[#101111] border-white relative z-10 flex-1 rounded-2xl border bg-white dark:bg-[#101111]  shadow-lg shadow-gray-400/50 dark:shadow-black/30">
      <div className="border-black/20 absolute inset-x-0 inset-y-8 z-[-1] border-t dark:border-white/20" />
      <div className="border-black/20 absolute inset-y-0 inset-x-8 z-[-1] border-l dark:border-white/20" />
      <div className="-mt-0.5">
        <div className="mt-4 mr-2 ml-4 flex items-center gap-6 rounded-xl bg-neutral-200 dark:bg-slate-800">
          <div className="-m-2">{icon}</div>
          <div className="truncate py-2 pr-4 text-sm font-bold text-slate-700 dark:text-slate-300">
            {title}
          </div>
        </div>
      </div>
      <div className="p-4 pl-12 text-sm text-left text-slate-600 dark:text-slate-400">
        {desc}
      </div>
    </div>
  );
}

export default FeaturedCard;

// import { FaStar } from "react-icons/fa"; // Import the star icon
// function FeaturedCard({ icon, title, desc, promotion }) {
//   return (
//     <div className="dark:border-[#101111] border-white relative z-10 flex-1 rounded-2xl border bg-white dark:bg-[#101111]  shadow-lg shadow-gray-400/50 dark:shadow-black/30">
//       <div className="border-black/20 absolute inset-x-0 inset-y-8 z-[-1] border-t dark:border-white/20" />
//       <div className="border-black/20 absolute inset-y-0 inset-x-8 z-[-1] border-l dark:border-white/20" />
//       <div className="-mt-0.5">
//         <div className="mt-4 mr-2 ml-4 flex items-center gap-6 rounded-xl bg-neutral-200 dark:bg-slate-800">
//           <div className="-m-2">{icon}</div>
//           <div className="truncate py-2 pr-4 text-sm font-bold text-slate-700 dark:text-slate-300">
//             {title}
//           </div>
//         </div>
//       </div>
//       {promotion && (
//           <div className="flex items-center bg-[#DB6B31] text-white text-xs px-3 py-1 rounded-xl mr-6">
//             <FaStar className="mr-1 text-yellow-400" />
//             <span>Promoted</span>
//           </div>
//         )}
//       <div className="p-4 pl-12 text-sm text-left text-slate-600 dark:text-slate-400">
//         {desc}
//       </div>
//     </div>
//   );
// }

// export default FeaturedCard;

// import { FaStar } from "react-icons/fa"; // Import the star icon

// function FeaturedCard({ icon, title, desc, promotion }) {
//   return (
//     <div className="dark:border-[#101111] border-white relative z-10 flex-1 rounded-2xl border bg-white dark:bg-[#101111] shadow-lg shadow-gray-400/50 dark:shadow-black/30">
//       <div className="border-black/20 absolute inset-x-0 inset-y-8 z-[-1] border-t dark:border-white/20" />
//       <div className="border-black/20 absolute inset-y-0 inset-x-8 z-[-1] border-l dark:border-white/20" />
//       <div className="-mt-0.5">
//         <div className="mt-4 mr-2 ml-4 flex items-center justify-between gap-6 rounded-xl bg-neutral-200 dark:bg-slate-800">
//           <div className="-m-2 flex items-center">
//             {icon}
//             <div className="truncate py-2 pr-4 text-sm font-bold text-slate-700 dark:text-slate-300 ml-4">
//               {title}
//             </div>
//           </div>
//           {promotion && (
//             <div className="flex items-center bg-[#DB6B31] text-white text-xs px-3 py-1 rounded-xl mr-6"> {/* Added margin-right (mr-6) */}
//               <FaStar className="mr-1 text-yellow-400" /> {/* Star icon */}
//               <span>Promoted</span>
//             </div>
//           )}
//         </div>
//       </div>
//       <div className="p-4 pl-12 text-sm text-left text-slate-600 dark:text-slate-400">
//         {desc}
//       </div>
//     </div>
//   );
// }

// export default FeaturedCard;


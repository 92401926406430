import { Link } from "react-scroll";
import { motion } from "framer-motion";
import { useReadingProgress } from "../Common/Progress";
import ProfileMenu from "./ProfileMenu";
import Switch from "../Common/Switch"; // Import the switch component

const HeaderNav = ({ darkMode, setDarkMode }) => {
  const completion = useReadingProgress();

  return (
    <motion.nav
  initial={{ opacity: 0 }}
  animate={{ opacity: 1 }}
  id="nav"
  className="sm:container sm:mx-auto"
>
  <div
    className={`fixed inset-x-0 block sm:flex sm:justify-between sm:items-center text-gray-900 dark:text-white top-0 z-40 md:justify-around py-3 md:py-2 bg-white/70 dark:bg-[#0d0c0e]/70 backdrop-filter backdrop-blur-lg bg-opacity-30 ease-in-out duration-700`}
  >
    <span
      id="progress-bar"
      style={{
        transform: `translateX(${completion - 100}%)`,
      }}
      className="absolute top-0 w-full transition-transform duration-300 h-[2px] bg-amber-500 dark:bg-amber-500 rounded-xl"
    />

    <div className="flex justify-between items-center md:ml-5 px-4 sm:px-0 w-full">
      <Link to="hero" smooth={true} offset={-70} duration={800}>
        <motion.h1
          initial={{ x: -50, opacity: 0, scale: 0.5 }}
          animate={{ x: 0, opacity: 1, scale: 1 }}
          transition={{ duration: 0.5 }}
          className="text-3xl text-neutral-600 dark:text-neutral-300 font-normal cursor-pointer sm:mr-2" // Add a small margin to the right for spacing
        >
          Vanshaj &nbsp;
          <span className="font-extrabold text-[#DB6B31]">Raghuvanshi</span>
        </motion.h1>
      </Link>

      {/* Mobile View - Theme Switcher & Profile */}
      <motion.div
        initial={{ x: 50, opacity: 0, scale: 0.5 }}
        animate={{ x: 0, opacity: 1, scale: 1 }}
        transition={{ duration: 0.5 }}
        className="sm:hidden flex items-center gap-2 ml-3" // Reduced gap and added margin to reduce space between the text and the slider
      >
        {/* Slider Toggle Switch */}
        <Switch darkMode={darkMode} setDarkMode={setDarkMode} />
        <ProfileMenu />
      </motion.div>

      {/* Desktop View - Theme Switcher & Profile */}
      <motion.div
        initial={{ x: 50, opacity: 0, scale: 0.5 }}
        animate={{ x: 0, opacity: 1, scale: 1 }}
        transition={{ duration: 0.5 }}
        className="hidden sm:flex justify-between items-center flex-row px-5"
      >
        {/* Slider Toggle Switch */}
        <Switch darkMode={darkMode} setDarkMode={setDarkMode} />

        <div className="ml-5">
          <ProfileMenu />
        </div>
      </motion.div>
    </div>
  </div>
</motion.nav>


  );
};

export default HeaderNav;

import React, { Suspense } from "react";
import { Canvas } from "@react-three/fiber";
import { Environment, OrbitControls, useGLTF } from "@react-three/drei";
import logoGLB from "./logo_test.glb";

const Model = () => {
  const { scene } = useGLTF(logoGLB);
  return <primitive object={scene} />; // Optional offset
};

const ThreeDModel = () => {
  return (
    <Canvas style={{ height: "250px", width: "250px" }} camera={[5, 5, 1000]}>  {/* Adjusted camera position */}
      <ambientLight intensity={0.5} />
      <directionalLight position={[10, 10, 5]} intensity={1} />
      <Suspense fallback={null}>
        <Model />
      </Suspense>
      <OrbitControls enableZoom={false} enablePan={true} minPolarAngle={Math.PI / 2} maxPolarAngle={Math.PI / 2} />
      <Environment preset="sunset" />
    </Canvas>
  );
};

export default ThreeDModel;
